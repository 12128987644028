.banner-container {
  background: #FFDC39;
  /* padding: 2rem 0; */
  padding-top: 3rem;
}

.banner-container .banner-wrapper {
  width: 90%;
  height: 550px;
  margin: 0 auto;
  position: relative;
}

.banner-container .banner-wrapper .banner-image {
  width: 100%;
  background: #313437;
  height: auto;
}

.banner-container .banner-wrapper .banner-image img {
  /* width: 100%;
    height: 100%; */
  object-fit: contain;
}

.banner-container .banner-wrapper .banner-content {
  position: absolute;
  left: 65%;
  top: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  gap: 1rem;
}

.banner-container .banner-wrapper .banner-content h2 {
  font-size: 40px;
  text-align: start;
}

.banner-container .banner-wrapper .banner-content ul {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 0.5rem;
}

.banner-container .banner-wrapper .banner-content ul li {
  display: flex;
  align-items: end;
  gap: 0.5rem;
  margin: 0;
  /* justify-content: center; */
}

.banner-container .banner-wrapper .banner-content ul li img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.banner-container .banner-wrapper .banner-content ul li span {
  /* margin-top: 10px; */
}

.button-container button:hover {
  background-color: #0D0B03 !important;
}


/* .shipment-container .shipment-wrapper {
    width: 80%;

} */









/* Styles for mobile view */

@media screen and (max-width: 600px) {
  .banner-container {
    padding-top: 3rem;
    width: 100%;
    text-align: center;
  }

  .banner-container .banner-wrapper {
    width: 90%;
    height: 240px;
    margin: 0 auto;
    position: relative;
  }

  .banner-wrapper {
    padding-top: 2rem;
    flex-direction: column;
  }

  .banner-image {
    width: 100%;
    height: 350px;
    object-fit: none !important;
    margin-bottom: 20px;
  }

  .banner-image img {
    width: 100%;
    height: 250px;
    object-fit: fill !important;
  }

  .banner-content {
    margin-top: 0.3rem;
  }

  .banner-container .banner-wrapper .banner-content h2 {
    font-size: 15px;
    margin-top: 0%;
    line-height: 1.3;
  }

  .banner-container .banner-wrapper .banner-content ul {
    padding: 0;
    font-size: 20px;
    list-style-type: none;
  }

  .banner-content ul li span {
    display: flex;
    align-items: center;
    font-size: 0.5rem;
  }

  .banner-container .banner-wrapper .banner-content ul li img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  .button-container {
    flex-direction: column;
    padding-top: 0.5rem;
    gap: 10px;
  }

  .banner-container .banner-wrapper .banner-content {
    position: absolute;
    left: 65%;
    top: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    gap: 1rem;
  }

  .button-container button {
    width: 75%;
    font-size: 0.6rem;
    padding: 6px;
  }
}

/* @media screen and (max-width: 326px) {
  .banner-container {
    padding-top: 2rem;
    text-align: center;
  }

  .banner-wrapper {
    padding-top: 2rem;
    flex-direction: column;
  }

  .banner-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .banner-image img {
    width: 100%;
    height: 550px;
    border-radius: 10px;
  }

  .banner-container .banner-wrapper .banner-content {
    position: absolute;
    left: 65%;
    top: 15%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    gap: 0.5rem;
  }

  .banner-container .banner-wrapper .banner-content h2 {
    font-size: 14px;
    margin-top: 0%;
    line-height: 1.3;
  }

  .banner-content ul {
    padding: 0;
    list-style-type: none;
  }

  .banner-container .banner-wrapper .banner-content ul li {
    display: flex;
    align-items: end;
    font-size: 14px;
    gap: 0.5rem;
    margin: 0;

  }

  .banner-content ul li img {
    width: 20px;
    margin-right: 10px;
  }

  .button-container {
    flex-direction: column;
    padding-top: 4px;
    gap: 5px;
  }

  .button-container button {
    width: 80%;
    font-size: 0.8rem;
    padding: 8px;
  }
} */
@media (max-width: 768px) {
  .shipment-container {
    margin-top: 2.5rem;
  }

  .shipment-wrapper {
    flex-direction: column;
    /* width: 700px; */
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
  }

  .shipment-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shipment-image {
    width: 5rem;
    height: 5rem;
  }

  .status-icon {
    width: 4rem;
    height: 1rem;
  }

  .step-number {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.875rem;
  }

  .step-title {
    font-size: 1rem;
  }

  .step-description {
    font-size: 0.875rem;
  }
}

/* WhyChoose.css */
@media (max-width: 768px) {
  .why-choose-container {
    padding: 2rem 1rem;
    /* width: 700px; */
    display: flex;
    flex-direction: column;
  }

  .why-choose-card {
    padding: 2rem 1rem;
    /* width: 600px; */
  }

  .why-choose-image {
    width: 5rem;
    height: 4rem;
  }

  .why-choose-card h4 {
    font-size: 1.5rem;
  }

  .why-choose-card h5 {
    font-size: 2rem;
  }

  .why-choose-card p {
    font-size: 1rem;
  }

  .why-choose-card button {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
  }

  .why-choose-container h2 {
    font-size: 2rem;
  }

  .why-choose-container h3 {
    font-size: 1.2rem;
  }
}

/* Stats.css */
@media (max-width: 768px) {
  .stats-container {
    display: flex;
    /* flex-direction: column;   */
    flex-wrap: wrap;
    padding: 20px;
    justify-items: center;
    gap: 2rem;
  }

  .stats-wrapper {
    /* gap: 1.5rem; */
    padding: 1rem;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    /* display: contents; */
  }

  .stat-card {
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 calc(50% - 2rem);
    /* width: calc(50%-1rem); */
    text-align: center;
    /* width: 100%;
    justify-content: center; */
    padding: 0%;
    margin: 0%;
    gap: 1rem;
  }

  .stat-card img {
    width: 4.5rem;
    height: 4rem;
  }

  .stat-card h3 {
    font-size: 1.5rem;
  }

  .stat-card p {
    font-size: 0.875rem;
  }
}

/* Team.css */
@media (max-width: 768px) {
  .team-container {
    padding: 1rem;
    width: 100%;
    margin-top: 6px;
  }

  .team-container-heading {
    text-align: center;
    font-size: 2rem;
  }

  .team-wrapper {
    width: 100%;
  }

  .teamcard-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    padding: 10px;
    flex: 1 1 calc(50% - 2rem);
  }

  .member-card {
    width: 100%;
    height: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
    padding: 10px;
    flex: 1 1 calc(50% - 2rem);
    /* padding: auto; */
  }

  .member-card h4 {
    font-size: 1.25rem;
  }

  .member-card p {
    font-size: 0.875rem;
  }

  .image-card {
    height: 70%;
    width: full;
  }
}