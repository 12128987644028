.header-wrapper {
  width: 80%;
}

.header-container .logo-container {
  width: 80x;
  height: 80px;
}

.header-container .logo-container img {
  width: 100%;
  height: 100%;
}

.header-container .nav-container a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* color: #313437; */
}

@media (max-width: 768px) {
  .footer-wrapper {
    width: 100%;
    padding-top: 1.5rem;
  }

  .footer-container {
    width: 100%;
  }

  .footer-head {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 1rem;
  }

  .footer-head-contant {
    display: flex;
    align-items: center;
    padding: 0.6rem;
    width: 100%;
    flex-direction: row;
  }

  .footer-icon {
    padding: 0.8rem;
  }

  .footer-heading-text {
    font-size: 0.875rem;
    margin: 0;
  }

  .footer-content-text {
    font-size: 0.875rem;
  }

  /* .footer-icon{
    width: 100%;
  } */

  .contact-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }

  .items-start {
    align-items: center;
  }

  .footer-head .contact-card h3 {
    font-size: 1.25rem;
  }

  .footer-head .contact-card p {
    font-size: 1rem;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    flex: 1 1 calc(50% - 2rem);
  }


  /* .footer-container-contant {
    width: 100%;
    height: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 10px;
    flex: 1 1 calc(50% - 2rem);
  } */

  .footer-bottom h1 {
    font-size: 0.875rem;
  }

  .footer-bottom {
    margin-top: 1rem;
  }
}

/* Add this to your existing style.css */

@media (max-width: 600px) {
  .header-container {
    width: 100%;
  }

  .header-wrapper {
    width: 100%;
    margin: 0%;
  }

  /* .header-logo-contant{
    width: 100%;
  } */

  /* 
  .logo-container {
    width: 15rem;
    height: 15rem;
  }

  .logo-container img {
    height: 100%;
    width: 100%;
  }

  .nav-container {
    display: none;
  }

  .mobile-menu-button {
    display: block;
    padding: 0.5rem;
    border-radius: 0.375rem;
    background-color: #ffdc39;
  }

  .mobile-dropdown-menu {
    display: none;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 4rem;
    right: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  } */
  /* 
  .mobile-dropdown-menu a {
    padding: 1rem;
    border-bottom: 1px solid #f1f1f1;
    color: #000;
  }

  .mobile-menu-button.active + .mobile-dropdown-menu {
    display: flex;
  } */
}