@media (max-width: 768px) {
  .services-main-container {
    width: 100%;
    height: 250px;
    padding-top: 100px;
    margin: 0 auto;
    margin-top: 6rem;
    position: relative;
  }
  .Services-banner {
    width: 100%;
    height: 350px;
    object-fit: none !important;
    margin-bottom: 20px;
  }
  .banner-services-cointainer {
    width: 100%;
    height: 250px;
    object-fit: contain;
    object-fit: fill !important;
  }
}

/* Mobile view CSS */
@media (max-width: 768px) {
  .service-card-main {
    padding: 2rem 1rem;
    /* width: 700px; */
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }

  .services-card-container {
    /* grid-template-columns: 1fr; */
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .service-card-main .flex-row,
  .service-card-main .flex-row-reverse {
    flex-direction: row !important;
    margin: 0%;
/* Stack content in mobile */
    width: 100%;
  }

  /* .service-card-main {
    width: 100%; 
  } */

  .left-image img {
    height: auto;
    width: auto;
    object-fit: fill;
  }

  .service-card-main .p-6 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
